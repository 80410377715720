import './_obs-messages.scss';

export default class MessagesService {

  constructor() {
    this._snippets = {
      kontaktTel: '06196 99 5401',
      kontaktTelURI: 'tel:+49-6196-995401',
      kontaktFax: '06196 99 49 5401',
      kontaktMail: 'kundenservice@vr-smart-finanz.de',
      kontaktZeiten: 'Mo-Do 8-18 Uhr, Fr 8-17 Uhr',
      teilabsicherungRUNDUMSCHUTZ: 'Die Teilabsicherung gilt für die versicherbaren Risiken "Arbeitsunfähigkeit" und "Aufgabe der selbständigen Tätigkeit aus wirtschaftlichen Gründen und/oder Scheidung".',
      teilabsicherungARBEIT_LEBEN: 'Die Teilabsicherung gilt für das versicherbare Risiko "Aufgabe der selbständigen Tätigkeit aus wirtschaftlichen Gründen und/oder Scheidung".',
      teilabsicherungGESUNDHEIT: 'Die Teilabsicherung gilt für das versicherbare Risiko "Arbeitsunfähigkeit".'
    };
    this._messages = {
      gewerbeFinanzSchutz: {
        htmlTitle: 'GewerbeFinanzSchutz',
        htmlContent: '<div> Schützen Sie sich vor den Auswirkungen unvorhersehbarer Ereignisse und sichern Sie die Fortzahlung der vereinbarten Raten bei Arbeitslosigkeit, Scheidung und Arbeitsunfahigkeit ab - ebenso wie die Zahlung der Restforderung im Todesfall. Der Produktgeber für die Versicherung im Todesfall und im Fall der Arbeitsunfähigkeit ist die R+V Lebensversicherung AG ' +
        '(Niederlassung Wiesbaden). Der Produktgeber der Versicherung für die Aufgabe der selbstständigen Tätigkeit aus wirtschaftlichen Gründen ' +
        '(inkl. Scheidung) ist die ' +
        '<a class="underline" target="_blank" href="https://www.ruv.de/home/">R+V Allgemeine Versicherung AG</a>.',
        trackEventCategory: 'Zusatzinformationen',
        trackEventAktion: 'GFS',
        trackEventName: 'R+V-Link'
      },
      emnicon: {
        title: 'emnicon',
        content: 'Unser Kooperationspartner für die Durchführung der Terminvereinbarung online ist die emnicon AG.',
        trackEventCategory: 'Zusatzinformationen',
        trackEventAktion: 'emnicon',
        trackEventName: 'Info emnicon'
      },
      schufa: {
        title: 'SCHUFA',
        content: 'Unser Kooperationspartner ist die SCHUFA-Holding AG.',
        trackEventCategory: 'Zusatzinformationen',
        trackEventAktion: 'SCHUFA',
        trackEventName: 'Info SCHUFA'
      },
      creditReform: {
        title: 'Creditreform',
        content: 'Unser Kooperationspartner ist der Verband der Vereine Creditreform e.V.',
        trackEventCategory: 'Zusatzinformationen',
        trackEventAktion: 'Creditreform',
        trackEventName: 'Info Creditreform'
      },
      createCompany: {
        title: 'Unternehmensgründung',
        content: 'Wir freuen uns, dass Sie einen Finanzierungswunsch mit uns realisieren möchten! Leider begleiten wir nur Finanzierungsvorhaben von Unternehmen, die bereits länger als zwei Jahre am Markt bestehen. Wenden Sie sich für eine Existenzgründerfinanzierung gerne an Ihre Volksbank Raiffeisenbank vor Ort und lassen Sie sich dort persönlich zu Ihren Möglichkeiten beraten.',
        contentReDirect: 'Wir freuen uns, dass Sie einen Finanzierungswunsch mit uns realisieren möchten! Leider begleiten wir nur Finanzierungsvorhaben von Unternehmen, die bereits länger als zwei Jahre am Markt bestehen. Gerne leiten wir Sie wieder zurück auf die Seite der {0}. Dort können Sie Ihre unverbindliche Anfrage stellen und sich von Ihrer Volksbank Raiffeisenbank beraten lassen.',
        trackEventCategory: 'Disqualifizierungsmerkmal',
        trackEventAktion: 'Unternehmen-jünger-als-2-Jahre'
      },
      serverError: {
        title: 'Ein Fehler ist aufgetreten',
        content: 'Der gewünschte Service steht Ihnen derzeit leider nicht zur Verfügung.',
        trackEventCategory: 'Serverfehler',
        trackEventAktion: 'Allgemeiner Serverfehler'
      },
      kalkulationError: {
        title: 'Ein Fehler ist aufgetreten',
        content: 'Die Kalkulation Ihres Finanzierungswunsches konnte leider nicht erfolgreich durchgeführt werden. Versuchen Sie es bitte durch Klick auf "Berechnen" am Ende der Seite erneut.',
        trackEventCategory: 'Serverfehler',
        trackEventAktion: 'Allgemeiner Serverfehler (Kalkulation)'
      },
      iCodeUngueltigError: {
        title: 'Vielen Dank für Ihr Interesse',
        content: '<p>Guten Tag,</p>\
        <p>wir freuen uns über ihr Interesse am Unternehmerkredit. <br>\
        Sie haben ihre Anfrage innerhalb von 14 Tagen <b>nicht fortgesetzt</b>, weshalb wir Ihre Daten wieder gelöscht haben.</p>\
        <p></p>\
        <p><b>Gut zu wissen:</b> Sie können gerne einfach eine neue Anfrage stellen. <br>\
        Online über den Button "Jetzt Anfrage starten" oder direkt vor Ort in Ihrer betreuenden Volksbank Raiffeisenbank. </p>\
        <p></p>\
        <p>Freundliche Grüße<br>\
        Ihre VR Smart Finanz</p>\
        <p></p>\
        <p>Haben Sie noch Fragen?<br>\
        Dann rufen Sie uns gerne an.<br>\
        Tel. 06196 99 5401</p>\
        <p></p>\
        <p>Wir sind erreichbar: <br>\
        Mo - Do 8-18 Uhr, Fr 8-17 Uhr.</p>',
        trackEventCategory: 'Serverfehler',
        trackEventAktion: 'Allgemeiner Serverfehler (Wiedereinstieg Interessent)'
      },
      foreignBank: {
        title: 'Bankverbindung',
        content: 'Wir freuen uns, dass Sie einen Finanzierungswunsch mit uns realisieren möchten! Für die erfolgreiche Bestellung Ihrer Finanzierung benötigen Sie ein deutsches Bankkonto. Wenden Sie sich einfach an Ihre Volksbank Raiffeisenbank vor Ort. Diese hilft Ihnen gerne weiter.',
        contentReDirect: 'Wir freuen uns, dass Sie einen Finanzierungswunsch mit uns realisieren möchten! Die Voraussetzung für die Bestellung Ihrer Finanzierung ist eine deutsche Bankverbindung. Gerne leiten wir Sie wieder zurück auf die Seite der {0}. Dort können Sie Ihre unverbindliche Anfrage stellen und ein Berater nimmt anschließend Kontakt zu Ihnen auf.',
        trackEventCategory: 'Disqualifizierungsmerkmal',
        trackEventAktion: 'Keine-deutsche-Bankverbindung'
      },
      selectAUTCHE: {
        title: 'Wohnsitz',
        content: 'Wir freuen uns, dass Sie einen Finanzierungswunsch mit uns realisieren möchten! Leider können ausschließlich Kunden mit Wohnsitz in Deutschland unser Online-Finanzierungsangebot nutzen. Bitte setzen Sie sich als Kunde mit Wohnsitz im Ausland für eine individuelle Beratung mit einer Volksbank Raiffeisenbank vor Ort in Verbindung. Diese hilft Ihnen gerne weiter.',
        contentReDirect: 'Wir freuen uns, dass Sie einen Finanzierungswunsch mit uns realisieren möchten! Leider können ausschließlich Kunden mit Wohnsitz in Deutschland unser Online-Finanzierungsangebot nutzen. Gerne leiten wir Sie wieder zurück auf die Seite der {0}. Dort können Sie Ihre unverbindliche Anfrage stellen und sich von Ihrer Volksbank Raiffeisenbank beraten lassen.',
        trackEventCategory: 'Disqualifizierungsmerkmal',
        trackEventAktion: 'Wohnsitz-nicht-in-Deutschland'
      },
      sonstiges: {
        title: 'Wohnsitz',
        content: 'Wir freuen uns, dass Sie einen Finanzierungswunsch mit uns realisieren möchten! Leider können ausschließlich Kunden mit Wohnsitz in Deutschland unser Online-Finanzierungsangebot nutzen. Bitte setzen Sie sich als Kunde mit Wohnsitz im Ausland für eine individuelle Beratung mit einer Volksbank Raiffeisenbank vor Ort in Verbindung. Diese hilft Ihnen gerne weiter.',
        contentReDirect: 'Wir freuen uns, dass Sie einen Finanzierungswunsch mit uns realisieren möchten! Leider können ausschließlich Kunden mit Wohnsitz in Deutschland unser Online-Finanzierungsangebot nutzen. Gerne leiten wir Sie wieder zurück auf die Seite der {0}. Dort können Sie Ihre unverbindliche Anfrage stellen und ein Berater nimmt anschließend Kontakt zu Ihnen auf.',
        trackEventCategory: 'Disqualifizierungsmerkmal',
        trackEventAktion: 'Wohnsitz-nicht-in-Deutschland (sonstige)'
      },
      geschaeftsabbruch: {
        title: 'Vertretungsberechtigung',
        content: 'Wir freuen uns, dass Sie einen Finanzierungswunsch mit uns realisieren möchten! Wenn Sie nicht Inhaber oder Geschäftsführer des genannten Unternehmens sind, ist Ihre Volksbank Raiffeisenbank vor Ort gerne für Sie da. Diese kann individuell auf Ihre Wünsche und Bedürfnisse eingehen und berät Sie gerne.',
        contentReDirect: 'Wir freuen uns, dass Sie einen Finanzierungswunsch mit uns realisieren möchten! Wenn Sie nicht Inhaber oder Geschäftsführer des genannten Unternehmens sind, leiten wir Sie gerne wieder zurück auf die Seite der {0}. Dort können Sie Ihre unverbindliche Anfrage stellen und ein Berater nimmt anschließend Kontakt zu Ihnen auf.',
        trackEventCategory: 'Disqualifizierungsmerkmal',
        trackEventAktion: 'Antragssteller-nicht-Inhaber-oder-Geschäftsführer'
      },
      geschaftsfuehrerInfo: {
        title: 'Vertretungsberechtigung',
        content: 'Die Bestellung kann lediglich vom Geschäftsführer bzw. Inhaber Ihres Unternehmens durchgeführt werden, da dieser später bei der Video-Legitimation verifiziert wird.',
        trackEventCategory: 'Zusatzinformationen',
        trackEventAktion: 'Vertretungsberechtigung',
        trackEventName: 'Info Vertretungsberechtigung'
      },
      rechtsformAbgelehnt: {
        title: 'Rechtsform',
        content: 'Wir freuen uns, dass Sie einen Finanzierungswunsch mit uns realisieren möchten! Für Ihre Rechtsform empfehlen wir eine passgenaue Beratung. Hierfür ist Ihre Volksbank Raiffeisenbank vor Ort gerne für Sie da.',
        contentReDirect: 'Wir freuen uns, dass Sie einen Finanzierungswunsch mit uns realisieren möchten! Für Ihre Rechtsform empfehlen wir eine passgenaue Beratung. Gerne leiten wir Sie wieder zurück auf die Seite der {0}. Dort können Sie Ihre unverbindliche Anfrage stellen und sich von Ihrer Volksbank Raiffeisenbank beraten lassen.',
        trackEventCategory: 'Disqualifizierungsmerkmal',
        trackEventAktion: 'Rechtsform-nicht-erlaubt'
      },
      rechtsformPruefungError: {
        title: 'Rechtsform',
        content: 'Wir freuen uns, dass Sie einen Finanzierungswunsch mit uns realisieren möchten! Für Ihre Rechtsform empfehlen wir eine passgenaue Beratung. Hierfür ist Ihre Volksbank Raiffeisenbank vor Ort gerne für Sie da.',
        contentReDirect: 'Wir freuen uns, dass Sie einen Finanzierungswunsch mit uns realisieren möchten! Für Ihre Rechtsform empfehlen wir eine passgenaue Beratung. Gerne leiten wir Sie wieder zurück auf die Seite der {0}. Dort können Sie Ihre unverbindliche Anfrage stellen und ein Berater nimmt anschließend Kontakt zu Ihnen auf.',
        trackEventCategory: 'Disqualifizierungsmerkmal',
        trackEventAktion: 'Rechtsform-nicht-ermittelbar'
      },
      inhaberZuJung: {
        title: 'Mindestalter',
        content: 'Wir freuen uns, dass Sie einen Finanzierungswunsch mit uns realisieren möchten! Um unser Finanzierungsangebot nutzen zu können, müssen Sie jedoch als Inhaber oder Geschäftsführer mindestens 20 Jahre alt sein. Bitte setzen Sie sich mit Ihrer Volksbank Raiffeisenbank vor Ort in Verbindung - dort berät man Sie gerne zu Ihren Finanzierungsmöglichkeiten.',
        contentReDirect: 'Wir freuen uns, dass Sie einen Finanzierungswunsch mit uns realisieren möchten! Um unser Finanzierungsangebot nutzen zu können, müssen Sie jedoch als Inhaber oder Geschäftsführer mindestens 20 Jahre alt sein. Gerne leiten wir Sie wieder zurück auf die Seite der {0}. Dort können Sie Ihre unverbindliche Anfrage stellen und ein Berater nimmt anschließend Kontakt zu Ihnen auf.',
        trackEventCategory: 'Disqualifizierungsmerkmal',
        trackEventAktion: 'Antragssteller-zu-jung'
      },
      adresseNichtGefunden: {
        title: 'Unternehmenssuche',
        content: 'Wir freuen uns, dass Sie einen Finanzierungswunsch mit uns realisieren möchten! Leider ist der beDirect GmbH & Co., unserer Partnerin bei der Adresssuche, die von Ihnen eingegebene Adresse nicht bekannt. Bitte wenden Sie sich daher für die Bestellung Ihrer Finanzierung direkt an Ihre Volksbank Raiffeisenbank vor Ort. Diese hilft Ihnen gerne weiter.',
        contentReDirect: 'Wir freuen uns, dass Sie einen Finanzierungswunsch mit uns realisieren möchten! Leider ist der beDirect GmbH & Co., unserer Partnerin bei der Adresssuche, die von Ihnen eingegebene Adresse nicht bekannt. Gerne leiten wir Sie wieder zurück auf die Seite der {0}. Dort können Sie Ihre unverbindliche Anfrage stellen und ein Berater nimmt anschließend Kontakt zu Ihnen auf.',
        trackEventCategory: 'Disqualifizierungsmerkmal',
        trackEventAktion: 'Adresse-nicht-bekannt'
      },
      Kontakt: {
        htmlTitle: 'Kontakt',
        htmlContent:  '<div id="kontaktContent">' +
                      '  <div id="kontaktAnsprache" class="mb-3">' +
                      '    Haben Sie noch Fragen?<br>' +
                      '    Dann rufen Sie uns an!' +
                      '  </div>' +
                      '  <div id="kontaktTelefon" class="mb-3">' +
                      '     <span class="phoneImage mr-2"></span>' +
                      '     <a href="' + this._snippets.kontaktTelURI + '" class="phoneMobile" target="_blank">' + this._snippets.kontaktTel + '</a>' +
                      '     <span class="phoneDesktop">' + this._snippets.kontaktTel + '</span>' +
                      '  </div>' +
                      '  <div id="kontaktZeiten" class="mb-4">' +
                      '     Gerne sind wir für Sie erreichbar:<br>' +
                      '     <span class="Zeiten">' + this._snippets.kontaktZeiten + '</span>' +
                      '  </div>' +
                      '  <div id="kontaktUnterschrift">' +
                      '     Ihre VR Smart Finanz' +
                      '  </div>' +
                      '</div>'
        ,
        trackEventCategory: 'Zusatzinformationen',
        trackEventAktion: 'Kontaktdaten',
        trackEventName: 'Info Kontaktdaten'
      },
      wbInfo: {
        title: 'Wirtschaftlich Berechtigte',
        content: 'Wirtschaftlich Berechtigte sind alle natürlichen Personen, die mehr als 25 % der Kapital- oder Stimmrechte Ihres Unternehmens direkt halten oder indirekt kontrollieren, auf deren Veranlassung hin eine Geschäftsbeziehung begründet wird oder die als Begünstigte bestimmt sind.',
        trackEventCategory: 'Zusatzinformationen',
        trackEventAktion: 'Wirtschaftlich Berechtigte',
        trackEventName: 'Info Wirtschaftlich Berechtigte'
      },
      wbLoeschen: {
        title: 'Wirtschaftlich berechtigte Person löschen',
        content: 'Soll Ihre wirtschaftlich berechtigte Person wirklich gelöscht werden?.',
        confirmButton: 'Ja, löschen',
        cancelButton: 'Abbrechen',
        trackEventCategory: 'Zusatzinformationen',
        trackEventAktion: 'Wirtschaftlich Berechtigte',
        trackEventName: 'Lösche Wirtschaftlich Berechtigte'
      },
      wbNachweisInfo: {
        title: 'Wirtschaftlich Berechtigte',
        content: 'Damit wir Ihren Finanzierungsbetrag auszahlen können, benötigen wir einen Nachweis darüber, dass die von Ihnen getätigten Angaben richtig sind. Als Nachweise dienen Unterlagen aus einer sogenannten neutralen Quelle. Dies können u.a. Kopien des Gesellschaftsvertrages / der Satzung, Auszüge aus dem Jahresabschlussbericht oder entsprechende Bestätigungen Ihres Steuerberaters, Wirtschaftsprüfers oder Notars sein.',
        trackEventCategory: 'Zusatzinformationen',
        trackEventAktion: 'WB Nachweis',
        trackEventName: 'Info WB Nachweis'
      },
      vrSmartFinanzBank: {
        htmlTitle: 'VR Smart Finanz Bank GmbH',
        htmlContent: 'Die VR Smart Finanz ist Partner der {{$ctrl.params.length>0 && $ctrl.params[0] == "hvb" ? "Hypovereinsbank" : "Volksbanken Raiffeisenbanken"}} und eine Marke der VR Smart Finanz Bank GmbH und VR Smart Finanz AG.',
        trackEventCategory: 'Zusatzinformationen',
        trackEventAktion: 'VR Smart Finanz Bank GmbH',
        trackEventName: 'Info VR Smart Finanz Bank GmbH'
      },
      vrSmartFinanz: {
        htmlTitle: 'VR Smart Finanz',
        htmlContent: 'Die VR Smart Finanz ist Partner der {{$ctrl.params.length>0 && $ctrl.params[0] == "hvb" ? "Hypovereinsbank" : "Volksbanken Raiffeisenbanken"}} und eine Marke der VR Smart Finanz Bank GmbH und VR Smart Finanz AG.',
        trackEventCategory: 'Zusatzinformationen',
        trackEventAktion: 'VR Smart Finanz',
        trackEventName: 'Info VR Smart Finanz'
      },
      bonitaetsmanager: {
        title: 'Bonitätsmanager',
        content: 'Der Bonitätsmanager ist ein digitaler Service der VR Smart Finanz und unterstützt Selbstständige, Freiberufler sowie kleine Gewerbe- und Geschäftskunden dabei, ihre Kreditwürdigkeit schnell und einfach zu optimieren. Anhand der von Ihnen eingegebenen Daten erhalten Sie relevante Hinweise, um Ihre Kreditwürdigkeit zu verbessern und können diese mit anderen Unternehmen Ihrer Branche oder mit dem regionalen Durchschnitt vergleichen.',
        trackEventCategory: 'Zusatzinformationen',
        trackEventAktion: 'Bonitätsmanager',
        trackEventName: 'Info Bonitätsmanager'
      },
      BLZ_NOT_FOUND: {
        title: 'Bankverbindung',
        content: 'Wir freuen uns, dass Sie einen Finanzierungswunsch mit uns realisieren möchten! Unter der von Ihnen eingegebenen IBAN konnten wir keine gültige deutsche Bankverbindung finden. Bitte überprüfen Sie Ihre Eingabe. Für weitere Fragen steht Ihnen die Volksbank Raiffeisenbank in Ihrer Nähe gerne partnerschaftlich zur Seite.',
        contentReDirect: 'Wir freuen uns, dass Sie einen Finanzierungswunsch mit uns realisieren möchten! Unter der von Ihnen eingegebenen IBAN konnten wir keine gültige deutsche Bankverbindung finden. Bitte überprüfen Sie Ihre Eingabe. Gerne leiten wir Sie wieder zurück auf die Seite der {0}. Dort können Sie Ihre unverbindliche Anfrage stellen und ein Berater nimmt anschließend Kontakt zu Ihnen auf.',
        trackEventCategory: 'Disqualifizierungsmerkmal',
        trackEventAktion: 'Keine-gültige-Bankleitzahl'
      },
      BLZ_INAKTIV: {
        title: 'Bankverbindung',
        content: 'Wir freuen uns, dass Sie einen Finanzierungswunsch mit uns realisieren möchten! Unter der von Ihnen eingegebenen IBAN konnten wir keine gültige deutsche Bankverbindung finden. Bitte überprüfen Sie Ihre Eingabe. Für weitere Fragen steht Ihnen die Volksbank Raiffeisenbank in Ihrer Nähe gerne partnerschaftlich zur Seite.',
        contentReDirect: 'Wir freuen uns, dass Sie einen Finanzierungswunsch mit uns realisieren möchten! Unter der von Ihnen eingegebenen IBAN konnten wir keine gültige deutsche Bankverbindung finden. Bitte überprüfen Sie Ihre Eingabe. Gerne leiten wir Sie wieder zurück auf die Seite der {0}. Dort können Sie Ihre unverbindliche Anfrage stellen und ein Berater nimmt anschließend Kontakt zu Ihnen auf.',
        trackEventCategory: 'Disqualifizierungsmerkmal',
        trackEventAktion: 'Keine-gültige-Bankleitzahl'
      },
      korrekteErfassungPersoenlicheAngaben: {
        title: 'Video-Legitimation',
        content: 'Bei der Video-Legitimation überprüfen wir Ihre persönlichen Angaben. Bei Abweichungen zwischen den von Ihnen gemachten Angaben und den Angaben auf Ihrem Ausweisdokument kann Ihre Legitimation nicht erfolgreich beendet werden. Des Weiteren werden Ihre Mobilfunknummer und E-Mail-Adresse für die Durchführung der Legitimation verwendet.',
        trackEventCategory: 'Zusatzinformationen',
        trackEventAktion: 'Video-Legitimation',
        trackEventName: 'Info Video-Legitimation'
      },
      uploadError: {
        title: 'Upload fehlgeschlagen',
        content: 'Die Datei konnte nicht hochgeladen werden. Bitte versuchen Sie es erneut.',
        trackEventCategory: 'Serverfehler',
        trackEventAktion: 'Upload fehlgeschlagen'
      },
      uploadLoeschenError: {
        title: 'Upload löschen fehlgeschlagen',
        content: 'Die Datei konnte nicht gelöscht werden. Bitte versuchen Sie es erneut.',
        trackEventCategory: 'Serverfehler',
        trackEventAktion: 'Upload löschen fehlgeschlagen'
      },
      uploadUeberschreiben: {
        htmlTitle: 'Datei "{{$ctrl.params[0]}}" überschreiben?',
        htmlContent: 'Achtung, Sie haben bereits eine Datei mit dem Namen "{{$ctrl.params[0]}}" hochgeladen. Wollen Sie die bestehende Datei überschreiben?',
        confirmButton: 'Überschreiben',
        cancelButton: 'Abbrechen',
        trackEventCategory: 'Zusatzinformationen',
        trackEventAktion: 'Upload überschreiben',
        trackEventName: 'Info Upload überschreiben'
      },
      vertretungsberechtigungUnternehmensgruendung: {
        title: 'Vertretungsberechtigung und Unternehmensgründung',
        htmlContent: '<p>Wir freuen uns, dass Sie einen Finanzierungswunsch mit uns realisieren möchten!</p>'
          + '<p>Wenn Sie nicht Inhaber oder Geschäftsführer des Unternehmens sind oder Ihr Unternehmen noch '
          + 'keine zwei Jahre am deutschen Markt besteht, ist Ihre Volksbank Raiffeisenbank vor Ort für Sie da. '
          + 'Diese kann individuell auf Ihre Wünsche und Bedürfnisse eingehen und berät Sie gerne.</p>',
        reDirect: true,
        trackEventCategory: 'Disqualifizierungsmerkmal',
        trackEventAktion: 'Vertretungsberechtigung-und-Unternehmensgründung-nicht-gegeben'
      },
      unternehmensgruendung: {
        title: 'Unternehmensgründung',
        htmlContent: '<p>Wir freuen uns, dass Sie einen Finanzierungswunsch mit uns realisieren möchten!</p>'
          + '<p>Wenn Ihr Unternehmen noch keine zwei Jahre am deutschen Markt besteht, '
          + 'ist Ihre Volksbank Raiffeisenbank vor Ort für Sie da. '
          + 'Diese kann individuell auf Ihre Wünsche und Bedürfnisse eingehen und berät Sie gerne.</p>',
        reDirect: true,
        trackEventCategory: 'Disqualifizierungsmerkmal',
        trackEventAktion: 'Vertretungsberechtigung-und-Unternehmensgründung-nicht-gegeben'
      },
      wohnsitzMindestalter: {
        title: 'Wohnsitz und Mindestalter',
        htmlContent: '<p>Wir freuen uns, dass Sie einen Finanzierungswunsch mit uns realisieren möchten!</p>'
          + '<p>Leider können ausschließlich Kunden mit Wohnsitz in Deutschland und einem Mindestalter von 20 Jahren '
          + 'unser Online-Finanzierungsangebot nutzen. Bitte setzen Sie sich für eine individuelle Beratung mit '
          + 'Ihrer Volksbank Raiffeisenbank vor Ort in Verbindung. Diese hilft Ihnen gerne weiter.</p>',
        reDirect: true,
        trackEventCategory: 'Disqualifizierungsmerkmal',
        trackEventAktion: 'Wohnsitz-und-Mindestalter-nicht-gegeben'
      },
      steuerberater: {
        title: 'Bestätigung Ihrer Jahresabschlüsse:',
        htmlContent:
          '<ul class="custom-checklist">'
          + '  <li class="checklist-item-dot mb-3"><span class="is-bold">Stempel und Unterschrift</span> eines Steuerberaters, externen Buchhalters, Rechtsanwalts oder Notares sind ersichtlich.</li>'
          + '  <li class="checklist-item-dot ">Anstelle eines Stempels ist auch der Zusatz "Steuerberater" mit seinen Adressdaten gültig.</li>'
          + '</ul>',
        trackEventCategory: 'Zusatzinformationen',
        trackEventAktion: 'Steuerberater',
        trackEventName: 'Info Steuerberater für Checkliste'
      },
      uploadInfo: {
        title: 'Upload Ihrer Jahresabschlüsse',
        htmlContent:'<p>Zur Prüfung Ihres Finanzierungsantrags benötigen wir Ihre Einnahmen-überschussrechnung oder Gewinn-und-Verlust-Rechnung zusätzlich als digitales Dokument.</p>'
        + '<p>Relevant sind die Seiten, auf denen Ihre Einnahmen und Ausgaben ersichtlich sind. Außerdem die Seiten mit dem jeweiligen Stempel und der Unterschrift Ihres Steuerberaters.'
        + '<br><br><p class="checklist-item-hinweis">Sie können Fotos oder Scans in den Dateitypen PDF, PNG, TIF und JPEG verwenden Es sind bis zu 10 Dateien, maximal 10 MB pro Datei als Upload möglich.</p></div> '
        + '<br><br><p class="checklist-item-hinweis">Eine Betriebswirtschaftliche Auswertung (BWA) ist nicht ausreichend, da sie keiner gesetzlichen Pflicht und damit keiner rechtlichen Vorgabe unterliegt, nach welcher sie erstellt werden muss.</p></div>',
        trackEventCategory: 'Zusatzinformationen',
        trackEventAktion: 'Upload Info',
        trackEventName: 'Upload-Info'
      },
      uploadInfoBWA: {
        title: 'Upload Ihrer Jahresabschlüsse',
        htmlContent:'<p>Zur Prüfung Ihres Finanzierungsantrags benötigen wir Ihre Einnahmen-überschussrechnung oder Gewinn-und-Verlust-Rechnung zusätzlich als digitales Dokument.</p>'
          + '<p>Relevant sind die Seiten, auf denen Ihre Einnahmen und Ausgaben ersichtlich sind. Außerdem die Seiten mit dem jeweiligen Stempel und der Unterschrift Ihres Steuerberaters.'
          + '<br><br><p class="checklist-item-hinweis">Sie können Fotos oder Scans in den Dateitypen PDF, PNG, TIF und JPEG verwenden Es sind bis zu 10 Dateien, maximal 10 MB pro Datei als Upload möglich.</p></div> ',
        trackEventCategory: 'Zusatzinformationen',
        trackEventAktion: 'Upload Info',
        trackEventName: 'Upload-Info'
      },
      finanzkennzahlen: {
        title: 'Finanzkennzahlen',
        htmlContent:
           '<ul class="custom-checklist">'
          + '  <li class="checklist-item-dot ">Betriebseinnahmen</li>'
          + '  <li class="checklist-item-dot ">Gesamtabschreibung</li>'
          + '  <li class="checklist-item-dot ">Zinsaufwand</li>'
          + '  <li class="checklist-item-dot ">Jahresüberschuss</li>'
          + '  <li class="checklist-item-hinweis mt-3 ">Entnehmen Sie diese Ihrer Einnahmenüberschussrechnung (EÜR) oder Ihrer Gewinn-und-Verlust-Rechnung (GuV). Halten Sie diese Unterlagen in digitaler Form zum Upload bereit.</li>'
          + '  <li class="checklist-item-hinweis mt-3 ">Eine Bestätigung der eingereichten Finanzkennzahlen liegt vor, wenn die Unterschrift Ihres Steuerberaters plus Stempel oder anstelle des Stempels seine Adressdaten ersichtlich sind.</li>'
          + '  <li class="checklist-item-hinweis mt-3 ">Eine Betriebswirtschaftliche Auswertung (BWA) ist nicht ausreichend.</li>'
          + '</ul>',
        trackEventCategory: 'Zusatzinformationen',
        trackEventAktion: 'Finanzkennzahlen',
        trackEventName: 'Info Finanzkennzahlen für Checkliste'
      },
      bwaInfo: {
        title: 'Betriebswirtschaftliche Auswertung (BWA)',
        htmlContent:
        '<p>Eine Betriebswirtschaftliche Auswertung (BWA) ist <span class="is-bold">nicht ausreichend</span>, da sie keiner gesetzlichen Pflicht und damit keiner rechtlichen Vorgabe unterliegt nach welcher sie erstellt werden muss.',
        trackEventCategory: 'Zusatzinformationen',
        trackEventAktion: 'BWA',
        trackEventName: 'Info BWA für Finanzkennzahlen'
      },
      bedirect: {
        title: 'beDirect GmbH & Co. KG',
        htmlContent: '<p>Unsere Partnerin bei der Adresssuche, die beDirect GmbH & Co. KG, ist ein gemeinsames Unternehmen von Creditreform und Bertelsmann und die führende Anbieterin von Adressmanagement und Data Quality Services in Deutschland.</p>',
        reDirect: true,
        trackEventCategory: 'Zusatzinformationen',
        trackEventAktion: 'bedirect'
      },
      bilanzstichtagInfo: {
        title: 'Bilanzstichtag',
        htmlContent:
          '<ul class="custom-checklist">'
          + '  <li class="checklist-item-no-space mt-3 ">Der Bilanzstichtag ist in der Regel der 31.12. eines Jahres.</li>'
          + '  <li class="checklist-item-hinweis mt-3 ">Der Abschluss darf maximal 24 Monate zurück liegen.</li>'
          + '  <li class="checklist-item-no-space mt-3 is-small is-grey">Möglich sind zum Beispiel: 31.12.2022 oder 31.12.2021</li>'
          + '</ul>',
        trackEventCategory: 'Zusatzinformationen',
        trackEventAktion: 'Finanzkennzahlen',
        trackEventName: 'Info Bilanzstichtag'
      },
      betriebseinnahmen: {
        title: 'Umsatzerlöse / Betriebseinnahmen',
        htmlContent:
          '<ul class="custom-checklist">'
          + '  <li class="checklist-item-dot ">Umsatzerlös</li>'
          + '  <li class="checklist-item-dot ">Gesamteinnahmen</li>'
          + '  <li class="checklist-item-dot ">Summe Betriebseinnahmen (Erlöse)</li>'
          + '  <li class="checklist-item-dot ">Alternative: Rohertrag / Rohergebnis</li>'
          + '  <li class="checklist-item-hinweis mt-3 ">Bitte verwenden Sie die Summe / Gesamtoption</li>'
          + '</ul>',
        trackEventCategory: 'Zusatzinformationen',
        trackEventAktion: 'Finanzkennzahlen',
        trackEventName: 'Info Umsatzerlöse / Betriebseinnahmen'
      },
      gesamtabschreibung: {
        title: 'Gesamtabschreibung',
        htmlContent:
          '<ul class="custom-checklist">'
          + '  <li class="checklist-item-dot ">Abschreibung</li>'
          + '  <li class="checklist-item-dot ">Afa</li>'
          + '  <li class="checklist-item-dot ">Absetzung für Abnutzung</li>'
          + '  <li class="checklist-item-dot ">Gesamtabschreibung</li>'
          + '  <li class="checklist-item-hinweis mt-3 ">Bitte verwenden Sie die Summe / Gesamtoption</li>'
          + '  <li class="checklist-item-hinweis ">Wenn diese Position nicht vorhanden ist, erfassen Sie die Abschreibungen mit dem Wert "0"</li>'
          + '  <li class="checklist-item-hinweis ">Wird eine BWA als Basis verwendet, sind die in der BWA verbuchten Abschreibungen anzugeben.</li>'
          + '</ul>',
        trackEventCategory: 'Zusatzinformationen',
        trackEventAktion: 'Finanzkennzahlen',
        trackEventName: 'Info Gesamtabschreibung'
      },
      zinsaufwand: {
        title: 'Zinsaufwand',
        htmlContent:
          '<ul class="custom-checklist">'
          + '  <li class="checklist-item-dot ">Zinsen und Ähnliche</li>'
          + '  <li class="checklist-item-dot ">Zinsaufwendungen</li>'
          + '  <li class="checklist-item-hinweis mt-3 ">Bitte verwenden Sie die Summe / Gesamtoption</li>'
          + '  <li class="checklist-item-hinweis ">Wenn diese Position nicht vorhanden ist, erfassen Sie die Zinsaufwendungen mit dem Wert "0"</li>'
          + '</ul>',
        trackEventCategory: 'Zusatzinformationen',
        trackEventAktion: 'Finanzkennzahlen',
        trackEventName: 'Info Zinsaufwand'
      },
      jahresueberschuss: {
        title: 'Jahresüberschuss / Gewinn',
        htmlContent:
          '<ul class="custom-checklist">'
          + '  <li class="checklist-item-dot ">Jahresüberschuss / Jahresfehlbetrag</li>'
          + '  <li class="checklist-item-hinweis mt-3 ">Geben Sie den steuerlichen oder alternativ den handels-rechtlichen Gewinn an</li>'
          + '  <li class="checklist-item-hinweis ">Bei einem Jahresfehlbetrag tragen Sie bitte entsprechend ein "Minus" vor Ihrer Zahl ein</li>'
          + '  <li class="checklist-item-hinweis ">Das "Ergebnis der gewöhnlichen Geschäftstätigkeit" wird nicht anerkannt</li>'
          + '  <li class="checklist-item-hinweis ">Wird eine BWA als Basis verwendet, so ist hier das vorläufige Ergebnis anzugeben.</li>'
          + '</ul>',
        trackEventCategory: 'Zusatzinformationen',
        trackEventAktion: 'Finanzkennzahlen',
        trackEventName: 'Info Jahresüberschuss / Gewinn'
      },
      ausweisDokument: {
        title: 'Ausweisdokument',
        content: 'Dies kann ein gültiger Personalausweis oder ein Reisepass nebst aktueller Meldebescheinigung sein.',
        trackEventCategory: 'Zusatzinformationen',
        trackEventAktion: 'Ausweisdokument',
        trackEventName: 'Info Ausweisdokument'
      },
      keineVersicherungGewaehlt: {
        title: 'Schutz Ihrer Finanzierung',
        htmlContent: '<p>Sie haben sich für keinen Versicherungsschutz entschieden.</p>'
          + '<p>Sind Sie sicher, dass Sie Ihren Finanzierungsvertrag nicht gegen die Folgen aus '
          + 'Arbeitsausfall, Arbeitsunfähigkeit, Todesfall absichern möchten?</p> ',
        confirmButton: 'Weiter ohne Schutz',
        trackEventCategory: 'Zusatzinformationen',
        trackEventAktion: 'kein Versicherungsschutz gewählt',
        trackEventName: 'Info kein Versicherungsschutz gewählt'
      },
      laufzeitAngepasst: {
        title: 'Schutz Ihrer Finanzierung',
        content: 'Das von Ihnen gewählte Schutzpaket führt zu einer Anpassung der Vertragslaufzeit.',
        trackEventCategory: 'Zusatzinformationen',
        trackEventAktion: 'GFS Laufzeitanpassung',
        trackEventName: 'Info GFS Laufzeitanpassung'
      },
      wbZuJung: {
        title: 'Altersbeschränkung',
        content:  'Wir freuen uns, dass Sie einen Finanzierungswunsch mit uns realisieren möchten! Um unser Finanzierungsangebot nutzen zu können, ' +
                  'müssen wirtschaftlich Berechtigte zwischen 18 und 100 Jahren alt sein.',
        trackEventCategory: 'Disqualifizierungsmerkmal',
        trackEventAktion: 'WirtschaftlichBerechtigter-zu-jung'
      },
      legitimationFehler: {
        title: 'Ihre Legitimation',
        htmlContent: '<p>Der Online-Legitimationsservice ist derzeit nicht verfügbar.</p>'
          + '<p class="mt-4">Sie können über die Ihnen zugesandte E-Mail wieder in die Anfrage einsteigen. '
          + 'Schauen Sie zur Sicherheit auch in den Spam-Ordner Ihres Posteingangs.</p>',
        isLegitimationFehler: true,
        trackEventCategory: 'Zusatzinformationen',
        trackEventAktion: 'Legitimationsfehler',
        trackEventName: 'Info Legitimationsfehler'
      },
      vertragsbedingungen: {
        title: 'Vertragsbedingungen',
        htmlContent:
          '<div>'
          + 'Der Vertragsschluss steht unter der aufschiebenden Bedingung einer beanstandungsfreien Geldwäsche-, Compliance- und '
          + 'Finanzsanktionsprüfung sowie der Übereinstimmung Ihrer Angaben (zu Ihrem Umsatz, dem Jahresüberschuss, den Abschreibungen '
          + 'und den Zinsaufwendungen) mit Ihren tatsächlichen Verhältnissen.'
          + '</div>',
        trackEventCategory: 'Zusatzinformationen',
        trackEventAktion: 'GFS Laufzeitanpassung',
        trackEventName: 'Info GFS Laufzeitanpassung'
      },
      steuerlicheAngabenSteuerId: {
        title: 'Steuer-ID',
        htmlContent:
          '<p>Entnehmen Sie die Steuer-ID (11-stellige Nummer) folgenden Unterlagen:</p>'
          + '<ul class="custom-checklist">'
          + '  <li class="checklist-item-dot">Lohnsteuerbescheinigung: Die Steuer-ID ist unter dem Punkt\n' +
          'Identifikationsnummer zu finden.</li>'
          + '  <li class="checklist-item-dot ">Einkommenssteuerbescheid: Die IdNr. steht auf der linken\n' +
          'Seite im oberen Eck, direkt oberhalb der Steuernummer.</li>'
          + '  <li class="checklist-item-dot ">Informationsschreiben Ihres Finanzamtes: Auch auf\n' +
          'Informationsschreiben des Finanzamtes ist die Steuer-ID\n' +
          'neben der Steuernummer zu finden.</li>'
          + '</ul>',
        trackEventCategory: 'Zusatzinformationen',
        trackEventAktion: 'Wirtschafts-ID/Steuer-ID des Vertragspartners',
        trackEventName: 'Info Wirtschafts-ID/Steuer-ID des Vertragspartners'
      },
      steuerlicheAngabenSteuerNr: {
        title: 'Steuernummer',
        htmlContent:
          '<p> <span class="is-signal">!</span>  Geben Sie für juristische Personen die für die Besteuerung nach dem Einkommen geltende Steuernummer an.</p>'
          + '<p>Entnehmen Sie die Steuernummer (10- bis 13-stellige Nummer) folgenden Unterlagen:</p>'
          + '<ul class="custom-checklist">'
          + '  <li class="checklist-item-dot ">Einkommenssteuerbescheid: Die Steuernummer steht auf der linken\n' +
                'Seite im oberen Eck, direkt unterhalb der Steueridentifikationsnummer.</li>'
          + '  <li class="checklist-item-dot ">Informationsschreiben Ihres Finanzamtes: Auch auf\n' +
                'Informationsschreiben des Finanzamtes ist die Steuernummer\n' +
                'zu finden. Sie können Ihre Steuernummer auch beim zuständigen Finanzamt erfragen.</li>'
          + '</ul>',
        trackEventCategory: 'Zusatzinformationen',
        trackEventAktion: 'Steuernummer des Inhabers',
        trackEventName: 'Info Steuernummer des Inhabers'
      },
      steuerlicheAngabenWB: {
        title: 'Steuer-ID',
        htmlContent:
          '<p>Entnehmen Sie die Steuer-ID (11-stellige Nummer) folgenden Unterlagen:</p>'
          + '<ul class="custom-checklist">'
          + '  <li class="checklist-item-dot ">Lohnsteuerbescheinigung: Die Steuer-ID ist unter dem Punkt\n' +
          'Identifikationsnummer zu finden.</li>'
          + '  <li class="checklist-item-dot ">Einkommenssteuerbescheid: Die IdNr. steht auf der linken\n' +
          'Seite im oberen Eck, direkt oberhalb der Steuernummer.</li>'
          + '  <li class="checklist-item-dot ">Informationsschreiben deines Finanzamtes: Auch auf\n' +
          'Informationsschreiben des Finanzamtes ist die Steuer-ID\n' +
          'neben der Steuernummer zu finden.</li>'
          + '</ul>',
        trackEventCategory: 'Zusatzinformationen',
        trackEventAktion: 'Wirtschafts-ID/Steuer-ID des WBs',
        trackEventName: 'Info Wirtschafts-ID/Steuer-ID des WBs'
      },
      absicherungsluecke: {
        title: 'R+V GewerbeFinanzSchutz',
        htmlContent:
            '<div>'
          + '<p>Die maximal versicherte Ratenhöhe von {{$ctrl.params[1] | obsNumber : 2}} € '
          + 'wird mit dem aktuellen Finanzierungswunsch überschritten. Der Abschluss einer '
          + 'R+V GewerbeFinanzSchutz ist in Form einer Teilabsicherung weiterhin möglich.</p>'
          + '<table class="table table-sm border-bottom">'
          + '<tr><td>Absicherungsbedarf:</td><td class="text-right">{{$ctrl.params[0] | obsNumber : 2}} €</td></tr>'
          + '<tr><td>davon versichert:</td><td class="text-right">{{$ctrl.params[1] | obsNumber : 2}} €</td></tr>'
          + '<tr class="is-bold is-grey"><td>Absicherungslücke:</td><td class="text-right">{{$ctrl.params[2] | obsNumber : 2}} €</td></tr>'
          + '</table>'
          + '<p>{{$ctrl.snippet("teilabsicherung" +$ctrl.params[3])}}</p>'
          + '<p>Der Absicherungsbedarf für den Todesfall ist im vollem Umfang abgedeckt.</p>'
          + '</div>',
        trackEventCategory: 'Zusatzinformationen',
        trackEventAktion: 'GFS Absicherungslücke',
        trackEventName: 'Info GFS Absicherungslücke'
      },
      ausschlussklausel: {
        title: 'Ausschlussklausel',
        htmlContent:
        '<div>Die R+V versichert über das GewerbeFinanzSchutz jeden ohne Gesundheitsüberprüfung. '
        + 'Erkrankt die versicherte Person während der ersten 24 Monate der Vertragslaufzeit und beansprucht den Leistungsfall, '
        + 'dann prüft die R+V zunächst, ob die versicherte Person bereits bis zu 12 Monate vor Vertragsabschluss diese Erkrankung hatte.'
        + 'Falls ja, greift der Versicherungsschutz der R+V nicht. Das gilt nur für schwere Vorerkrankungen.</div>',
        trackEventCategory: 'Zusatzinformationen',
        trackEventAktion: 'Informationen zum Paket des GewerbeFinanzSchutzes',
        trackEventName: 'Info Ausschlussklausel'
      },
      einnahmenUndAusgaben: {
        title: 'Ihre Nachweise zu Einnahmen und Ausgaben',
        htmlContent: ' <p>Relevant sind die Seiten aus Ihren Jahresabschlüssen – bzw. Betriebswirtschaftlichen Auswertungen, sofern Sie diese vorab als Finanzkennzahlen eingeben konnten,'
        + ' was nur in gewissen Zeiträumen möglich ist – {{$ctrl.params[0]}} und {{$ctrl.params[1]}} (EÜR oder GuV),'
        + ' auf denen Ihre Angaben zu Umsatzerlös, Gesamtabschreibung, Zinsaufwand, Gewinn oder Jahresüberschuss bzw. Vorläufigem Ergebnis ersichtlich sind.'
        + ' <p>Bei Einreichung von BWAs bitte auch die Bestätigung Ihres Steuerberaters, dass kein Jahresabschluss vorliegt, hochladen. </p>'
        + '<span ng-if="{{$ctrl.params[2]}}>Außerdem die Seiten aus Ihren Jahresabschlüssen bzw. Betriebswirtschaftliche Auswertungen (EÜR oder GuV)'
        + 'mit dem jeweiligen Stempel und der Unterschrift Ihres Steuerberaters.</span>'
        + '</p>',
        trackEventCategory: 'Zusatzinformationen',
        trackEventAktion: 'Information Nachweise zu Einnahmen und Ausgaben'
      },
      gesellschaftsvertrag: {
        title: 'Ihr Gesellschaftsvertrag',
        htmlContent: ' <div ng-switch on="$ctrl.vorgang.firma.rechtsformCode">'
        + ' <div ng-switch-when="6">'
        + 'Das Geldwäschegesetz (GwG) verpflichtet uns, die wirtschaftlich Berechtigten unseres Vertragspartners festzustellen. Dies umfasst neben einer Prüfung der Kapitalanteile ebenfalls die Prüfung der Stimmrechte. Relevant ist der Gesellschaftsvertrag der'
        + '  GmbH & Co. KG (sofern vorhanden) und der Komplementärgesellschaft (GmbH) sowie alle Nachträge, aus denen die Verteilung der Stimmrechte ersichtlich ist.'
        + '</div>'
        + '<div ng-switch-when="G">'
        + 'Das Geldwäschegesetz (GwG) verpflichtet uns, die wirtschaftlich Berechtigten unseres Vertragspartners festzustellen. Dies umfasst neben einer Prüfung der Kapitalanteile ebenfalls die Prüfung der Stimmrechte. Relevant ist der Gesellschaftsvertrag der'
        + 'GmbH & Co. oHG (sofern vorhanden) und der Komplementärgesellschaft (GmbH) sowie alle Nachträge, aus denen die Verteilung der Stimmrechte ersichtlich ist.'
        + '</div>'
        + '<div ng-switch-default>'
        + '<p>Das Geldwäschegesetz (GwG) verpflichtet uns, die wirtschaftlich Berechtigten unseres Vertragspartners festzustellen.'
        + '    Dies umfasst neben einer Prüfung der Kapitalanteile ebenfalls die Prüfung der Stimmrechte. Relevant ist Ihr Gesellschaftsvertrag'
        + '   und alle Nachträge, aus denen die Verteilung der Stimmrechte ersichtlich ist.'
        + ' </p>'
        + '</div>'
        + '</div>',
        trackEventCategory: 'Zusatzinformationen',
        trackEventAktion: 'Information Gesellschaftsvertrag'
      },
      satzung: {
        title: 'Ihre Satzung',
        htmlContent: '<p>Das Geldwäschegesetz (GwG) verpflichtet uns, die wirtschaftlich Berechtigten unseres Vertragspartners festzustellen.'
        + 'Dies umfasst neben einer Prüfung der Kapitalanteile ebenfalls die Prüfung der Stimmrechte.</p>'
        + '<p>Relevant ist Ihre Satzung und alle Beschlüsse, aus denen die Verteilung der Stimmrechte ersichtlich ist.'
        + '</p>',
        trackEventCategory: 'Zusatzinformationen',
        trackEventAktion: 'Information Satzung'
      },
      nachweis_wb: {
        title: 'Ihre Nachweise zu wirtschaftlich berechtigten Personen',
        htmlContent: '<p>Damit wir Ihren Finanzierungsbetrag auszahlen können, benötigen wir einen Nachweis darüber, dass die von Ihnen zuvor getätigten Angaben richtig sind.</p>'
        + '<p>Als Nachweise dienen Unterlagen aus einer sogenannten neutralen Quelle.</p>'
        + '<p>Dies können u.a. Kopien des Gesellschaftsvertrages/der Satzung, Auszüge aus dem Jahresabschluss oder entsprechende Bestätigungen'
        + 'Ihres Steuerberaters, Wirtschaftsprüfers oder Notars sein.'
        + '</p>',
        trackEventCategory: 'Zusatzinformationen',
        trackEventAktion: 'Information nachweise zu wirtschaftlich berechtigten Personen'
      },
      nachweis_ag: {
        title: 'Ihre Nachweise zu Börsennotierung Ihrer Aktiengesellschaft',
        htmlContent: '<p>Damit wir Ihren Finanzierungsbetrag auszahlen können, '
        + 'benötigen wir einen Nachweis darüber, dass Ihre Aktiengesellschaft börsennotiert ist.'
        + '</p>',
        trackEventCategory: 'Zusatzinformationen',
        trackEventAktion: 'Information nachweise zu Börsennotierung Ihrer Aktiengesellschaft'
      },
      aufenthaltsbescheinigung: {
        title: 'Aufenthaltsbescheinigung Ihrer Gesellschafter',
        htmlContent: '<p>Sind Ihre Gesellschafter keine EU-Bürger, laden Sie bitte die Aufenthaltsbescheinigung der entsprechenden Gesellschafter hoch.</p>',
        trackEventCategory: 'Zusatzinformationen',
        trackEventAktion: 'Information aufenthaltsbescheinigung Ihrer Gesellschafter'
      },
      ausweis: {
        title: 'Ausweisdokument',
        htmlContent: '<p>Kopien Ihres Ausweisdokuments (Vorder- und Rückseite) zur Prüfung Ihrer Identität.</p>'
        + '<p>Dies kann ein gültiger Personalausweis oder ein Reisepass nebst aktueller Meldebescheinigung sein.</p>',
        trackEventCategory: 'Zusatzinformationen',
        trackEventAktion: 'Information Ausweisdokument'
      }

    };
  }

  getMessage(messageName) {
    return this._messages[messageName];
  }

    // Mit dieser Methode können die Text-Messages formatiert werden.
    // "Dies {0} ist ein {1} Text {2} mit genau 3 Platzhaltern, die sich im Array replacements befinden müssen."
  getMessageFormatted (messageContent, replacements) {
    if (replacements && messageContent) {
      if (!Array.isArray(replacements)) {
        replacements = [replacements];
      }

      for (let count = 0; count < replacements.length; count++) {
        if (replacements[count]) {
          messageContent = messageContent.split('{' + count + '}').join(replacements[count]); // replace all
        }
      }
    }

    return messageContent;
  }

  getSnippet (snippetName) {
    return this._snippets[snippetName];
  }

  getTrackEventCategory (messageName) {
    return (typeof this._messages[messageName].trackEventCategory !== 'undefined') ? this._messages[messageName].trackEventCategory : '';
  }

  getTrackEventAktion (messageName) {
    return (typeof this._messages[messageName].trackEventAktion !== 'undefined') ? this._messages[messageName].trackEventAktion : '';
  }

  getTrackEventName (messageName) {
    return (typeof this._messages[messageName].trackEventName !== 'undefined') ? this._messages[messageName].trackEventName : '';
  }

  isMessageAvailable (messageName) {
    return !!this._messages[messageName];
  }

  isMessageWithReDirect (messageName) {
    return !!this._messages[messageName] && !!this._messages[messageName].contentReDirect;
  }

}

